



















































































import { Component, Vue } from "vue-property-decorator";
import * as AUTH from "@/state/login";

@Component({
  components: {}
})
export default class Login extends Vue {
  created() {
    //AUTH.init();
    //if (AUTH.isSessionActive()) this.goToHome();
  }
  async requestToken() {
    if (!sessionStorage.getItem("verifier")) {
      this.login();
      return;
    }
  }
  // Login to IDP and request authorization code
  async login() {
    AUTH.login();
  }

  // Logout from IDP and remove tokens from session storage
  async logout() {
    AUTH.logout();
  }

  goToHome() {
    this.$router.push("/home");
  }
}
